header {
  border-bottom:1px #DFDFDF solid;
  background:#fff;
  position: fixed;
  top:0;
  right:0;
  z-index: 1000;
  width:100%;
  .pull-left {
    padding-top:20px;
    padding-bottom:20px;
  }
  .pull-right {
    font-family:$title-font-family;
    font-size:14px;
    padding-top:0;
    padding-bottom:40px;
    .menuitemhidden {
      display: none;
      @media (max-width:1000px) {
        display: block;;
      }
    }
    .energybtn {
      position: relative !important;
      top: 0px;
      float: right;
      right: 0;
      color: #737171;
      background: #e6e4e4;
      padding: 4px 13px 7px 13px;
      border-radius: 0 0 10px 10px;
      font-size: 12px;
      @media (max-width:1000px) {
        display: none;
      }
    }
    ul {
      clear: both;
      padding-top: 10px;
      list-style: none;
      @include respond-to(mobile-nav) { display:none; }
      li {
        display: inline-block;
        margin-left:10px;
        font-size:14px;
        font-weight:500;
        text-transform: uppercase;
        a {
          color:#333;
          padding:10px;
          line-height: 1em;
          display: inline-block;
          &:hover {
            text-decoration: none;
            color: #013668;
          }
        }
        &.active {
          a {
            background:#013668;
            color:#fff;
            border-radius: 100px;
            &:hover {
              color:#fff;
            }
          }
        }
      }
    }
  }
}

.circle {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  position: fixed;
  top:30px;
  right:35px;
  display: none;
  @include transitions( .5s ease-in-out);
  @include respond-to(mobile-nav) { display:block; }
}

.push-circle {
  right:calc(15.625em + 6px);
}


/* Navigation Button */
#mobile-burger-btn {
  display:none;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  position: fixed;
  margin:0;
  cursor: pointer;
  background:$blue;
  z-index: 100;
  text-align:center;
  @include transform(0deg);
  @include transitions( .5s ease-in-out);
  @include respond-to(mobile-nav) {
     display:inline-block;
     position:fixed;
     z-index:999;
     top:25px;
     right:25px;
   }
  div {
    display: inline-block;
    width: 28px;
    height: 54.5px;
    position: relative;
    top: 14px;
    right: 0;
  }
  span {
    display: block;
    position: absolute;
    height: 4.5px;
    width: 100%;
    background:white;
    opacity: 1;
    left: 0;
    @include border-radius(4.5px);
    @include transform(0deg);
    @include transitions( .25s ease-in-out);
    &:nth-child(1) {
      top: 0px;
      @include transform-origin(left center);
    }
    &:nth-child(2) {
      top:9px;
      @include transform-origin(left center);
    }
    &:nth-child(3) {
      top:18px;
      @include transform-origin(left center);
    }
  }
  &.menu-open {
    right:266px;
    @media (max-width:900px) {
      right:246px;
    }
    span {
      &:nth-child(1) {
        @include transform(45deg);
        top: -1.5px;
        left: 4px;
      }
      &:nth-child(2) {
        width: 0%;
        opacity: 0;
      }
      &:nth-child(3) {
        @include transform(-45deg);
        top: 19.5px;
        left: 4px;
      }
    }
  }
  &:hover {
    span {
    }
  }
}
