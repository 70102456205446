.additionalProjects {
  border-top:1px solid #E8EDEF;
  padding:50px 0;
  margin:50px 0 0;
  h3 {
    font-size:20px;
    color:#1F4D64;
    text-transform: uppercase;
    margin:0 0 40px;
  }
  ul {
    margin:0;
    column-count: 4;
    column-gap: 20px;
    @media(max-width:1150px) {
      column-count: 3;
    }
    @media(max-width:900px) {
      column-count: 2;
    }
    @media(max-width:650px) {
      column-count: 1;
    }
    li {
      display: block;
      color:#333;
      font-size:16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin:5px 0;
      a {
        color:#333;
      }
    }
  }
}

.project-header {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size:cover;
  margin-top:100px;
  min-height:495px;
  .overlay-txt {
    padding-top:0;
  }
  .inner-wrap {
    display:flex;
    max-width:1090px;
    min-height:495px;
    align-items: center;
    @media(max-width:700px) { display: block; padding-top:45px; }
  }
  .project-title {
    width:50%;
    padding: 0 5% 0 0;
    @media(max-width:700px) { width:100%; padding:0; margin:0 0 25px; }
    h4 {
      font-size:27px;
      color:#1F4D64;
      line-height: 1.3em;
      font-weight: 500;
      @media(max-width:900px) { font-size:23px; line-height:1.3em; }
      @media(max-width:600px) { font-size:19px; line-height:1.3em; }
      @media(max-width:450px) { font-size:16px; line-height:1.3em; }
    }
    h1 {
      font-size:46px;
      font-weight:700;
      color:#0C2B34;
      line-height: 1.2em;
      margin:15px 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media(max-width:900px) { font-size:40px; line-height:1.2em; }
      @media(max-width:600px) { font-size:36px; line-height:1.2em; }
      @media(max-width:450px) { font-size:32px; line-height:1.2em; }
    }
    h3 {
      font-size:14px;
      color:#fff;
      line-height: 1.3em;
      text-transform: uppercase;
      @media(max-width:900px) { font-size:13px; line-height:1.3em; }
      @media(max-width:600px) { font-size:12px; line-height:1.3em; }
      @media(max-width:450px) { font-size:11px; line-height:1.3em; }
    }
    a.btn {
      margin-top:25px;
    }
  }
  .project-details {
    width:50%;
    vertical-align: top;
    display: flex;
    align-content:space-between;
    flex-wrap:wrap;
    @media(max-width:700px) { width:100%; display:block; }
    ul {
      font-family:$title-font-family;
      font-size:14px;
      font-weight:700;
      width:50%;
      @media(max-width:900px) { width:100%; }
      li {
        display: block;
        margin: 0 0 20px;
        .lbl {
          text-transform: uppercase;
          color:#1F4D64;
          display:block;
          margin:0 0 5px;
        }
        .value { color:#fff; font-weight:500; }
      }

    }
    a.img-wrapper {
      border:5px #fff solid;
      display: block;
      height: 345px;
      overflow: hidden;
      width:100%;
      @media(max-width:900px) {
        height: 300px;
      }
      @media(max-width:750px) {
        height: 260px;
      }
      @media(max-width:700px) {
        height: 200px;
      }
      img {
        max-width: none;
        min-height: 100%;
        width: auto;
        @media(max-width:700px) {
          width:100%;
        }
      }
    }
  }
}

.project-txt {
  padding:75px 0;
  .inner-wrap {
    columns: 2;
    font-size:16px;
    line-height:1.5em;
    @media(max-width:900px) { columns: 1; font-size:15px; }
    @media(max-width:600px) { font-size:14px; }
    @media(max-width:450px) { font-size:13px; }
    p {
      margin:0 0 1.5em;
      &:first-child {
        font-size:24px;
        line-height:1.5em;
        color:#1F4D64;
        @media(max-width:900px) { font-size:20px; line-height:1.5em; }
        @media(max-width:600px) { font-size:18px; line-height:1.5em; }
        @media(max-width:450px) { font-size:17px; line-height:1.5em; }
      }
    }

  }
}
