
h1, h2, h3 {
    font-family:$title-font-family;
    font-weight:400;
}

h1 {
  font-size:34px;
  line-height:1.5em;
  margin:20px 0;
  @media (max-width:900px) { font-size:30px; line-height: 1.5em; }
  @media (max-width:600px) { font-size:26px; line-height: 1.5em; }
  @media (max-width:450px) { font-size:22px; line-height: 1.5em; }
}

.minor {
  color:$blue;
  font-size:24px;
  font-weight:700;
  font-family:$title-font-family;
  text-transform: uppercase;
  margin:25px 0;
}

.pminor {
  color:$blue;
  font-size:24px;
  font-weight:700;
  font-family:$title-font-family;
  text-transform: uppercase;
  margin:0 0;
}

.date {
  font-size:20px;
  color:$green;
  font-weight:500;
  line-height:1.35em;
  @media (max-width:900px) { font-size:18px; line-height: 1.35em; }
  @media (max-width:600px) { font-size:17px; line-height: 1.35em; }
  @media (max-width:450px) { font-size:16px; line-height: 1.35em; }
}

.read-more-date {
  color:white;
  font-size:19px;
  font-weight:500;
  font-style: italic;
  @media (max-width:900px) { font-size:18px; line-height: 1.5em; }
  @media (max-width:600px) { font-size:17px; line-height: 1.5em; }
  @media (max-width:450px) { font-size:16px; line-height: 1.5em; }
}
