#mobile-nav-items {
    background:#F8F8F8;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    top: 0;
    bottom: 0;
    height: 100%;
    z-index: 50;
    text-align:left;
    ul {
      margin:20px 0;
    }
    li {
      font-weight:700;
      font-size:15px;
      color:#fff;
      font-family:$title-font-family;
      text-transform: uppercase;
      display: block;
      padding:10px 20px;
      text-align:left;
      a {
        color:#5F5F5F;
        max-width:115px;
        display:inline-block;
        line-height: 1.25em;
        &:hover {
          text-decoration: none;
        }
        &.active {

        }
      }
    }
}
