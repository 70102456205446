button {
  border:0;
}
.btn {
  border:0;
  cursor: pointer;
  display:inline-block;
  line-height:1.34em;
  position:relative;
  background: transparent;
  font-family:$title-font-family;
  font-size: 17px;
  font-weight: 100;
  color: #FFFFFF;
  padding:15px 25px;
  font-size: 14px;
  color: #FFFFFF;
  border: 2px solid #FFFFFF;
  border-radius: 100px;
  font-weight:500;
  text-transform: uppercase;
  &:after {
   font-family: FontAwesome;
   content: "\f178";
   display: inline-block;
   padding-left: 10px;
   vertical-align: middle;
   position: relative;
   top:-1px;
  }
  &:active {
    top:1px;
  }
  &:hover {
    text-decoration: none;
    color: #FFFFFF;
  }
}

.back-btn {
  display:inline-block;

}


/* Social Media */
.social-items {
  margin:25px 0;
  display: block;
  font-family:"proxima-nova";
  background:#549130;
  padding:15px 25px;
  color:#fff;
  vertical-align:top;
  span {
    vertical-align: top;
    font-weight:500;
    display: inline-block;
    font-size:20px;
    margin-top:7px;
    @media(max-width:900px) { font-size:18px; }
    @media(max-width:600px) { font-size:17px;  }
    @media(max-width:450px) { font-size:15px; }
  }
  a {
    color:#1F4D64;
    margin-left:20px;
    display:inline-block;
    i {
      font-size:34px;
      color:#fff;
      @media(max-width:900px) { font-size:30px; }
      @media(max-width:600px) { font-size:28px;  }
      @media(max-width:450px) { font-size:26px; }
    }
  }
}
