html {
  box-sizing: border-box;
}

body {
  font:16px/1.5em $standard-font-family;
  position: relative;
  color:#666;
  @media (max-width:900px) { font-size:15px; line-height: 1.5em; }
  @media (max-width:600px) { font-size:14px; line-height: 1.5em; }
}

*, *:before, *:after {
  box-sizing: inherit;
}

a {
  cursor: pointer;
}

strong {
  font-weight:700;
}


fieldset {
  border:0;
  margin:0;
  padding:0;
}

img {
  max-width:100%;
}

.center { text-align:center; }
.clear { clear:both; }


#page-wrap {
  position: relative;
  right: 0;
  z-index: 100;
  width: 100%;
  transition: right 0.3s ease;
  background:#fff;
  min-height: 100vh;
  a {
    position: relative;
    &:active {
      top:1px;
    }
  }
}

#productFeatureLoader  { display:none; }


#loader {
  display:block;
  margin:35px auto;
}

#zero {
  display:none;
  text-align:center;
  color:#fff;
  padding:35px 0;
}

.default-pointer {
  cursor: default;
}

.mid {
  vertical-align: top;
}

.service-txt-wrap {
  display: inline-block;
  width:calc(100% - 380px);
  vertical-align: top;
  @media(max-width:1000px) {
    width:100%;
  }
  strong {
    color:$blue;
    font-size: 18px;

  }
}

.general-txt {
  p {
    margin:0 0 1.5em;
    &:nth-child(1) {
      font-size:26px;
      line-height:1.5em;
      color:$blue;
      @media(max-width:900px) { font-size:22px; line-height: 1.5em; }
      @media(max-width:600px) { font-size:20px; line-height: 1.5em; }
      @media(max-width:450px) { font-size:18px; line-height: 1.5em; }
    }
  }
  ul {
    margin:5px 0 1.5em;
    list-style: disc;
    li {
      margin-bottom: 12px;
      list-style: none;
      position: relative;
      padding: 0 0 0 30px;
    }
    :before {
      color:$blue;
      content: "•";
      font-size: 34px;
      left: 0px;
      position: absolute;
    }
  }
}

.hide-from-mobile {
  display: block;
  @media(max-width:900px) {
    display:none;
  }
}

.show-only-on-mobile {
  display: none;
  @media(max-width:900px) {
    clear:both;
    display:inline-block;
  }
}
.cat-dropdown {
  margin:20px 0;
}

.newline-small-devices {
  @media(max-width:600px) {
    float:none !important;
    text-align:center;
    margin:0;
      clear:both;
  }
}
.hide {display: none;}
