.about-template {
  .subbanner {
    margin:0;
  }
}

.about-top {
  padding:90px 0;
  background-image:no-repeat;
  background-size:cover;
  background-repeat:no-repeat;
  background-position: center;
  position: relative;
  p {
    margin:0 0 1.5em;
    color:#666;
    max-width:800px;
    &:first-child {
      font-size:26px;
      line-height: 1.35em;
      color:$blue;
      @media(max-width:900px) { font-size:22px; line-height: 1.35em; }
      @media(max-width:600px) { font-size:19px; line-height: 1.35em; }
      @media(max-width:450px) { font-size:17px; line-height: 1.35em; }
    }
  }
  .inner-wrap {
    position: relative;
    z-index: 150;
  }
  &:before {
    position: absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    content: " ";
    background-image: linear-gradient(-90deg, rgba(255,255,255,0.90) 8%, rgba(255,255,255,0.72) 60%);
  }
}

.about-items {
  .item {
    padding:90px 0;
    color:#fff;
    background-position: center;
    background-repeat:no-repeat;
    background-size:cover;
    position: relative;
    @media(max-width:900px) { padding:60px 0;  }
    @media(max-width:600px) { padding:40px 0; }
    @media(max-width:450px) { padding:30px 0; }

    h2 {
      font-size:29px;
      font-weight:700;
      line-height: 1.4em;
      font-family:$title-font-family;
      @media(max-width:900px) { font-size:24px; line-height: 1.4em; }
      @media(max-width:600px) { font-size:20px; line-height: 1.4em; }
      @media(max-width:450px) { font-size:18px; line-height: 1.4em; }
    }
    p {
      max-width:800px;
      margin:1.5em 0 0;
      font-weight: 300;
    }
    .inner-wrap {
      position: relative;
      z-index: 200;
    }
    &:before {
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      content: " ";
      z-index: 100;
    }
    &:nth-child(1) {
      &:before {
        background-image: linear-gradient(-90deg, rgba(88,145,190,0.80) 8%, rgba(74,152,195,0.34) 60%);
      }
    }
    &:nth-child(2) {
      &:before {
        background-image: linear-gradient(-90deg, rgba(0,54,104,0.98) 8%, rgba(0,54,104,0.62) 60%);
      }
    }
    &:nth-child(3) {
      h2 {
        color:$blue;
      }
      p {
        color:#666;
      }
      &:before {
        background-image: linear-gradient(-90deg, rgba(255,255,255,0.90) 8%, rgba(255,255,255,0.64) 60%);
      }
    }
    &:last-child{
      border-top: 1px solid #d8d8d8;
    }
  }
}
.accredit{
  text-align: center;
  h2{
    font-size: 29px;
    font-weight: 700;
    line-height: 1.4em;
    color: #013668;
    padding: 0 0 50px 0;
  }
}
