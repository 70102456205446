.feature-items {
  min-height:600px;
  display: flex;
  flex-wrap: wrap;
  align-items:stretch;
  @media(max-width:700px) {
    min-height:none;
  }
  .col {
    background-size:cover;
    background-repeat:no-repeat;
    background-position: center;
    width:50%;
    position: relative;
    @media(max-width:700px) {
      width:100%;
      height:600px;
    }
    .overlay {
      position: absolute;
      top:0;
      left:0;
      padding:70px;
      width:101%;
      height:600px;
      background-image: linear-gradient(-90deg, rgba(42,125,199,0.35) 5%, #2B7DC7 84%);
      color:#fff;
      display: table;
      @media(max-width:900px) { padding:50px; }
      .inner {
        display:table-cell;
        vertical-align:middle;
      }
      h3 {
        font-family:$title-font-family;
        font-size: 48px;
        color: #FFFFFF;
        line-height: 55px;
        text-shadow: 0px 2px 4px rgba(0,0,0,0.33);
        text-transform: uppercase;
        width:100%;
        font-weight:600;
        @media(max-width:900px) { font-size:42px; line-height: 49px; }
        @media(max-width:600px) { font-size:38px; line-height: 45px; }
        @media(max-width:450px) { font-size:35px; line-height: 42px; }
      }
      p {
        /*max-width:490px;*/
        width:65%;
        margin:10px 0;
        font-family: $sub-title-font-family;
        font-weight: 100;
        float: left;
        font-size: 20px;
        line-height: 34px;
      }
      a {
        clear: both;
        float: left;;
      }
      .desc {
        width:100%;
      }
    }
    &:nth-child(1) {
      text-align:right;
      @media(max-width:700px) { text-align: left; }
      .overlay {
        background-image: linear-gradient(90deg, rgba(0,54,104,0.35) 5%, #013668 83%);
        text-align: right;
        h3 {
          text-align:right;
          @media(max-width:700px) { text-align: left; }
        }
        p {
          text-align:right;
          float: right;
          @media(max-width:700px) { text-align: left; float: left;}
        }
        a {
          text-align:right;
          display: inline-block;
          float: right;
          @media(max-width:700px) { text-align: left;  float: left;}
        }
      }
    }
  }
}


.home-content {
  text-align:center;
  padding:60px 0;
  h1 {
    text-transform: uppercase;
    font-weight:700;
    margin:0;
    color:#333; 
  }
  .col-wrap {
    display: flex;
    justify-content: space-between;
    max-width:900px;
    margin:30px auto 0;
    width:90%;
    vertical-align: top;
    @media(max-width:900px) { display:block; }
    .col {
      width:235px;
      margin:0;
      vertical-align: top;
      @media(max-width:900px) { width:100%; }
      .img-wrap {
        text-align:center;
        width:100%;
        height:110px;
        vertical-align: middle;
        img {
          max-height:110px;
          vertical-align: middle;
        }
      }
      h3 {
        font-size:16px;
        font-weight:700;
        margin:10px 0;
        font-family:$title-font-family;
        font-weight:700;
        color:#666;
        text-transform: uppercase;
      }
      p {
        font-size: 14px;
        color: #666;
        line-height: 16px;
        font-family:$sub-title-font-family;
      }
      @media(max-width:900px) {
        &:nth-child(2) {
          margin:50px 0;
        }
      }
    }
  }
}
