.map {
  height:590px;
  margin:0;
  width:100%;
  display: block;
  .infoMarker {
    text-align:left;
    .btn {
      padding:12px 15px;
      font-size:12px;
      margin:10px 0;
    }
  }
}
