.loader {
  height:525px;
  display: block;
  width:100%;
  background:#F4F4F4 url('../imgs/productsLoader.gif') center no-repeat;
  z-index: 200;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  position: relative;
}

.leftBtn {
  position:absolute;
  top:220px;
  cursor:pointer;
  z-index: 300;
  left:0;
  i {
    font-size:76px;
    color:#fff;
    padding: 10px 15px 10px 10px;
    background: #4796C1;
  }
  &:active {
    top:221px;
  }
}

.rightBtn {
  @extend .leftBtn;
  left: auto;
  right:0;
}


.gallery {
  text-align:center;
  overflow:hidden;
  margin:30px auto 75px;
  width:100%;
  position:relative;
  padding: 30px 0;
  background-color: #F4F4F4;
  .slideshow-wrap {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    z-index: 100;
    padding: 30px 0;
    .slideshow-inner-img {
      background-position:center;
      background-repeat:no-repeat;
      background-size:cover;
      cursor: pointer;
      position:relative;
      display:inline-block;
      margin:0 15px;
      text-overflow: hidden;
      background:#013668;
      img {
        opacity:0.45;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
        position: relative;
        z-index: 10;
        max-width:none;
        height:525px;
      }
    }
    div.slick-active {
      img { opacity:1; }
    }
  }
}


@media (max-width:900px) {
  .leftBtn,
  .rightBtn { display:none !important; }
}
