
$title-font-family:	"proxima-nova";
$sub-title-font-family: "adelle";
$standard-font-family: "proxima-nova";

$container: 1090;

$red: #BE0000;
$green: #013668;
$blue: #013668;
$black: #333;

$break-small: 400px;
$break-large: 1130px;
$mobile-nav: 995px;
