// Web fonts

@mixin font-face($family, $file) {
  @font-face {
    font-family: $family;
    src: url('#{$path-font}/#{$file}-webfont.eot');
    src: url('#{$path-font}/#{$file}-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$path-font}/#{$file}-webfont.woff') format('woff'),
         url('#{$path-font}/#{$file}-webfont.ttf') format('truetype');
  }
}
@mixin respond-to($media) {
  @if $media == handhelds {
    @media only screen and (max-width: $break-small) { @content; }
  }
  @else if $media == medium-screens {
    @media only screen and (max-width: $break-large - 1) { @content; }
  }
  @else if $media == wide-screens {
    @media only screen and (min-width: $break-large) { @content; }
  }
  @else if $media == mobile-nav {
    @media only screen and (max-width: $mobile-nav) { @content; }
  }
}

@mixin flex() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin rotate($value) {
  -ms-transform: rotate($value);
  -webkit-transform: rotate($value);
  transform: rotate($value);
}

@mixin transform($value) {
  -webkit-transform: rotate($value);
  -moz-transform: rotate($value);
  -o-transform: rotate($value);
  transform: rotate($value);
}

@mixin transform-origin($value) {
  -webkit-transform-origin:$value;
  -moz-transform-origin:$value;
  -o-transform-origin:$value;
  transform-origin:$value;
}

@mixin transitions($value) {
  -webkit-transition:$value;
  -moz-transition:$value;
  -o-transition:$value;
  transition:$value;
}

@mixin text($size, $l-height: null, $weight: null, $color: null) {
  font-size: $size;
  line-height: $l-height;
  font-weight: $weight;
  color: $color;
}

@mixin columns($value) {
  -webkit-column-count: $value;
  -moz-column-count: $value;
  column-count: $value;
}

@mixin columns-width($value) {
  -webkit-column-width: $value;
  -moz-column-width: $value;
  column-width: $value;
}

@mixin border-radius($value) {
  border-radius:$value;
  -moz-border-radius:$value;
  -webkit-border-radius:$value;
  -o-border-radius:$value;
  background-clip: padding-box;
}

@mixin border-top-radius($radius) {
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
   -webkit-border-top-left-radius: $radius;
   border-top-left-radius: $radius;
   background-clip: padding-box;
}
@mixin border-right-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
     -webkit-border-top-right-radius: $radius;
     border-top-right-radius: $radius;
     background-clip: padding-box;
}
@mixin border-bottom-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
   -webkit-border-bottom-left-radius: $radius;
   border-bottom-left-radius: $radius;
   background-clip: padding-box;
}
@mixin border-left-radius($radius) {
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
     -webkit-border-top-left-radius: $radius;
     border-top-left-radius: $radius;
     background-clip: padding-box;
}
