.contact-form {
  padding-bottom:75px;
  display: inline-block;
  width:48%;
  vertical-align: top;
  @media(max-width:800px) {
    width:100%;
  }
  h2 {
    font-size:37px;
    font-weight:700;
    margin:0 0 25px;
    color:$blue;
    @media (max-width:900px) { font-size:34px; line-height: 1.5em; }
    @media (max-width:600px) { font-size:30px; line-height: 1.5em; }
    @media (max-width:450px) { font-size:28px; line-height: 1.5em; }
  }
  p {
    margin:0 0 1.5em;
    line-height: 1.5em;
  }
  form {

  }
}

.contact-details {
  padding-bottom:75px;
  width:44%;
  float: right;
  display: inline-block;
  vertical-align: top;
  @media(max-width:800px) {
    width:100%;

  }
  h3 {
    padding:20px 30px;
    line-height: 1em;
    color:white;
    background:$blue;
    font-size:19px;
    font-weight:500;
    @media (max-width:900px) { font-size:18px; line-height: 1em; }
    @media (max-width:600px) { font-size:16px; line-height: 1em; }
    @media (max-width:450px) { font-size:15px; line-height: 1em; }
  }
  ul {
    font-family:$title-font-family;
    li {
      border-top:1px white solid;
      font-size:19px;
      line-height: 1.5em;
      margin-bottom:20px;
      background:#E8EDEF;
      @media (max-width:900px) { font-size:18px; line-height: 1.5em; }
      @media (max-width:600px) { font-size:16px; line-height: 1.5em; }
      @media (max-width:450px) { font-size:15px; line-height: 1.5em; }
      a {
        color:#666;
      }
      p {
        padding:25px;
        margin:0;
        i {
          display:inline-block;
          margin-right:10px;
          margin-left:2px;
        }
      }
      hr {
        margin:0 25px;
        border-color:#fff;
      }
    }
  }
}
