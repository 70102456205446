.inner-wrap {
  max-width:$container + px;
  width:90%;
  margin:auto;
}

.main-content-wrap {
  padding-top:95px;
  h1#pageTitle{
    color: #013668;
    font-size: 34px;
    font-weight: 700;
    font-family: "proxima-nova";
    text-transform: uppercase;
    margin: 40px 0 25px 0;
  }
  h3{
    color: #013668;
    font-size: 24px;
    font-weight: 700;
    font-family: "proxima-nova";
    text-transform: uppercase;
    margin: 25px 0;
  }
}

ul.listServices {
  display:flex;
  flex-wrap:wrap;
  justify-content:flex-start;
  margin:35px 0;
  li {
    width:calc(33.3333333% - 15px);
    margin-bottom:25px;
    margin-left:20px;
    @media(min-width:1000px) {
      &:nth-child(3n+1) {
        margin-left:0;
      }
    }
    @media(max-width:1000px) {
      width:calc(50% - 10px);
      &:nth-child(2n+1) {
        margin-left:0;
      }
    }
    @media(max-width:650px) {
      width:100%;
      margin-left:0;
      margin-right:0;
    }
    .img-wrap {
      height:210px;
      overflow: hidden;
      img {
        max-width: 120%;
        min-height: 100%;
        min-width: 100%;
      }
    }
    .title {
      background:$blue;
      padding: 15px 20px;
      color:#fff;
      display: block;
      font-family:$title-font-family;
      font-weight: 100;
    }
  }
  li.lastservice {
      background: #4193C0;
      padding: 30px 27px 20px;
      h3{
        font-size: 36px;
        font-weight: 100;
      }
      p{
        font-size: 14px;
        padding: 10% 0;
        font-weight: 100;
      }
      a {
        color: #fff;
        font-weight: 100;
        font-family:$title-font-family;
      }
      a.link{
        text-decoration: underline;
        font-weight: 100;
      }
      :hover {
        text-decoration: none;
      }
  }
}

ul.listProjects {
  @extend ul.listServices;
  li {
    width:calc(33.3333333% - 15px);

    @media(max-width:1000px) {
      width:calc(33% - 15px);
      margin-left:20px;
      &:nth-child(2n+1) {
        margin-left:20px;
      }
      &:nth-child(3n+1) {
        margin-left:0;
      }
    }
    @media(max-width:750px) {
      width:calc(50% - 15px);
      margin-left:20px;
      &:nth-child(3n+1) {
        margin-left:20px;
      }
      &:nth-child(2n+1) {
        margin-left:0;
      }
    }
    @media(max-width:600px) {
      width:100%;
        margin-left:0;
      &:nth-child(3n+1) {
        margin-left:0;
      }
    }
    .title {
      background-image: none;
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
    img.logo2 {
      position: absolute;
      top: 0;
      left: 0;
    }
    .img-wrap a {
      display: block;
      height: 100%;
      width: 100%;
    }
  }
}

ul.listInnovations {
  padding:115px 0 0;
  li {
    background-repeat:no-repeat;
    background-size:cover;
    background-position: center;
    color:#fff;
    padding:90px 0;
    position: relative;
    &:before {
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      z-index: 100;
      content: " ";
      background-image: linear-gradient(-90deg, #2B7DC7 1%, rgba(42,125,199,0.25) 50%, #2B7DC7 99%);
    }
    .inner-wrap {
      position: relative;
      z-index: 200;
    }
    h2 {
      color:#fff;
      font-family:$title-font-family;
      margin:0 0 45px;
      font-size:48px;
      font-weight:600;
      line-height: 1.35em;
      @media(max-width:900px) { font-size:42px; line-height: 1.35em; }
      @media(max-width:600px) { font-size:38px; line-height: 1.35em; }
      @media(max-width:450px) { font-size:32px; line-height: 1.35em; }
    }
    p {
      color:#fff;
      margin:0 0 40px;
      font-family:$sub-title-font-family;
      font-size:27px;
      max-width:830px;
      line-height: 1.4em;
      font-weight:100;
      @media(max-width:900px) { font-size:23px; line-height: 1.4em; }
      @media(max-width:600px) { font-size:20px; line-height: 1.4em; }
      @media(max-width:450px) { font-size:18px; line-height: 1.4em; }
    }
    a.btn {
      margin:0;
    }
    &:nth-child(even) {
      &:before {
        background:linear-gradient(-90deg, #013668 1%, rgba(42,125,199,0.25) 50%, #013668 99%);

      }
    }

  }
}

.listNews {
  display: inline-block;
  margin:0 0 55px;
  padding:0;
  width:calc(100% - 380px);
  vertical-align: top;
  @media(max-width:1000px) {
    width:100%;
  }
  ul.listNewsItems {
    list-style: none;
    li {
      vertical-align: top;
      margin-top:35px;
      padding-top:35px;
      border-top:1px #E8EDEF solid;
      &:first-child {
        border-top:0;
        margin-top:0;
        padding-top:0;
      }
      .img-wrap {
        width:230px;
        height:230px;
        overflow: hidden;
        display: inline-block;
        margin-right:25px;
        vertical-align: top;
        border:1px #F2F5F6 solid;
        background: none;
        @media(max-width:600px) {
          margin-left:auto;
          margin-right:auto;
          display: block;
        }
        a {
          display: flex;
          align-items: center;
          width:100%;
          height:230px;
        }
        img {
          height:230px;
          max-width: none;
        }

      }
      .noimage {
        @extend .img-wrap;
        background: url(../imgs/comingsoon2.png) no-repeat center center;
        background-size: cover;
      }
      .txt-wrap {
        display: inline-block;
        width:calc(100% - 255px);
        vertical-align: top;
        @media(max-width:600px) {
          width:100%;
          margin-top:50px;
        }
        h2 {
          font-size:29px;
          margin:0 0 15px;
          line-height: 1.25em;
          font-weight:700;
          a { color:$blue; }
          @media(max-width:900px) {
            font-size:26px;
            line-height: 1.25em;
          }
          @media(max-width:600px) {
            font-size:22px;
            line-height: 1.25em;
          }
          @media(max-width:450px) {
            font-size:20px;
            line-height: 1.25em;
          }
        }
        .summary {
          margin:15px 0 0;
          color:#666;
          font-size:16px;
          line-height: 1.5em;
          @media(max-width:900px) {
            font-size:15px;
            line-height: 1.5em;
          }
          @media(max-width:600px) {
            font-size:14px;
            line-height: 1.5em;
          }
          @media(max-width:450px) {
            font-size:13px;
            line-height: 1.5em;
          }
        }
      }
    }
  }
  .paging {
    margin-top:75px;
    ul {
      margin:25px 0;
      list-style: none;
      display:block;
      width:100%;
      clear:both;
      li {
        display: inline-block;
        a {
          background:#1F4F64;
          padding:15px 17.5px;
          margin:0 7.5px 0 0;
          color:#fff;
          cursor: pointer;
          &.highlight {
            background:#549130;
          }
          &:hover {

          }
        }
      }
    }
  }
}

.listNewsCats {
  width:330px;
  display:inline-block;
  vertical-align: top;
  margin-left:50px;
  @media(max-width:1000px) {
    margin-left:auto;
    margin-right:auto;
    display: block;
    width: 100%;margin: 30px 0;
  }
  h3 {
    padding:20px 30px;
    line-height: 1em;
    color:white;
    background:$blue;
    font-size:20px;
    font-weight:500;
    @media(max-width:900px) { font-size:18px; line-height: 1em; padding:15px 25px; }
    @media(max-width:600px) { font-size:17px; line-height: 1em; padding:12px 20px; }
    @media(max-width:450px) { font-size:16px; line-height: 1em; padding:10px 15px; }
  }
  ul {
    font-family:$title-font-family;
    background:#E8EDEF;
    padding:20px 30px;
    margin-bottom:20px;
    @media(max-width:900px) { padding:12px 25px; }
    @media(max-width:600px) { padding:10px 20px;  }
    @media(max-width:450px) { padding:7.5px 15px; }
    li {
      border-top:1px white solid;
      font-size:18px;
      padding:15px 0;
      @media(max-width:900px) { padding:12px 0; font-size:16px; line-height: 1em; }
      @media(max-width:600px) { padding:10px 0; font-size:15px; line-height: 1em; }
      @media(max-width:450px) { padding:7.5px 0; font-size:14px; line-height: 1em; }
      a {
        color:#666;
        font-weight: 100;
        i {
          display:inline-block;
          margin-right:10px;
          margin-left:2px;
        }
        &.active {
          color:$green;
        }
      }
      h4 {
        color: #013668;
        font-weight: 400;
      }
      &:first-child {
        padding-top:5px;
        border-top:0;
      }
    }
  }
  .btn {
    width: 100% ;
    padding: 15px 30px;
    background: #4796C1;
    border-radius: 0;
  }
}

.news-story {
  vertical-align: top;
  margin-bottom: 50px;
  .details-wrap {
    width:calc(100% - 380px);
    line-height: 1.5em;
    display: inline-block;
    vertical-align: top;
    @media(max-width:1000px) {
      width:100%;
    }
    h1 {
      color:$blue;
      font-weight:700;
      font-size:37px;
      @media(max-width:900px) { font-size:34px; }
      @media(max-width:600px) { font-size:32px; }
      @media(max-width:450px) { font-size:30px; }
    }
    h3.date {
      margin:0 0 1.5em;
      color: #4896C1;
    }
    p {
      margin:0 0 1.5em;
    }
  }
  .listNewsCats {
    margin-top:0;
  }
}


ul.sub-cats {
  list-style: none;
  display: inline-block;
  margin:30px 0;
  li {
    display: inline-block;
    margin-left:25px;
    font-size:14px;
    font-family:$title-font-family;
    text-transform: uppercase;
    @media(max-width:900px) { font-size:13px; }
    @media(max-width:600px) { font-size:12px; }
    a {
      padding:7.5px 10px;
      color:#666;
      &:hover {
        text-decoration: none;
      }
      &.selected {
        background:$blue;
        color:white;
      }
    }
  }
}

ul.listRecruitment {
  display: block;
  margin:25px 0 50px;
  li {
    width:calc(50% - 22px);
    margin-bottom:44px;
    background:#E8EDEF;
    display: inline-block;
    &:nth-child(odd) {
      margin-right: 22px;
    }
    &:nth-child(even) {
      margin-left: 22px;
    }
    @media(max-width:900px) {
      width:100%;
      &:nth-child(odd) {
        margin-right: 0;
      }
      &:nth-child(even) {
        margin-left: 0;
      }
    }
    h2 {
      padding:30px 30px 15px;
      font-size:29px;
      line-height:1.35em;
      font-weight:700;
      a { color:$blue; }
      @media(max-width:900px) { padding:25px; font-size:26px; line-height:1.35em; }
      @media(max-width:600px) { padding:20px; font-size:24px; line-height:1.35em; }
      @media(max-width:450px) { padding:17.5px; font-size:22px; line-height:1.35em; }
    }
    h3 {
      padding:0 30px 30px;
      color: #4896C1;
      @media(max-width:900px) { padding:0 25px 25px; }
      @media(max-width:600px) { padding:0 20px 20px; }
      @media(max-width:450px) { padding:0 17.5px 17.5px; }
    }
    p {
      margin:0;
      width:100%;
      background:$blue;
      color:white;
      padding:15px 20px;
      font-size:17px;
      font-family:$title-font-family;
      line-height:1.35em;
      a {
        color:#fff;
      }
      @media(max-width:900px) { font-size:16px; line-height:1.35em; }
      @media(max-width:600px) { font-size:15px; line-height:1.35em; }
      @media(max-width:450px) { font-size:14px; line-height:1.35em; }
    }
  }
}

.enqinner {
  background: #E8EDEF;
  padding: 20px 30px;
  margin-bottom: 20px;
}
