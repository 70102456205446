
form {
	width:100%;
	button.btn {
		display:inline-block;
		border-radius:3px;
		font-weight:800;
		padding:18px 20px;
		line-height:1em;
		background: #013668;
	}
}
label { display:none; }
input, textarea {
	font-family:$standard-font-family;
	width:100%;
  border-radius: 3px;
	padding:10px;
	border:0;
	margin-bottom:18px;
	border: 1px solid #DDDDDD;
	color: #000;
}
textarea {
	min-height:170px
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
	-webkit-appearance:none;
}

#validation-msg {
	padding:25px;
	color:#333;
	margin:10px auto;
	width:100%;
	font-family:$title-font-family;
	font-weight:800;
	text-align:center;
	text-transform: uppercase;

}

.select_style {
	overflow: hidden;
	display: inline-block;
	position: relative;
	cursor: pointer;
  background: #fff;
  border-radius: 3px;
  font-family:$standard-font-family;
  font-size: 16px;
  width:100%;
	position:relative;
	border: 1px solid #DDDDDD;
	margin-bottom:9px;
  @media (max-width:900px) { font-size:15px; line-height: 1em; }
  @media (max-width:600px) { font-size:14px; line-height: 1em; }
  @media (max-width:450px) { font-size:12px; line-height: 1em; }
	&:after {
		content: "\f078";
		font-family: FontAwesome;
		position: absolute;
		color:#848694;
		top:8px;
		right:10px;
	}
	select {
	  -moz-appearance: none;
	  -webkit-appearance: none;
		appearance:none;
		background:none;
		background:transparent;
		border:none;
		cursor:pointer;
		outline:none;
		padding:10px;
	  width:100%;
		option { padding-right:45px; }
	}
}
