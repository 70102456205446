footer {
  color:#fff;
  margin:0;
  font-family:$title-font-family;
  font-size:14px;
  a {
    color:#fff;
  }
  .top {
    padding:40px 0;
    background:#013668;
    vertical-align: top;
    img {
      margin-right:35px;
      display: inline-block;
      vertical-align: top;
      @media(max-width:600px) {
        float: left;
        margin-bottom: 20px;
      }
    }
    ul {
      columns:2;
      display: inline-block;
      vertical-align: top;
      @media(max-width:600px) {
        width:100%;
        display: block;
        margin:35px auto;
        columns:1;
        margin-bottom: 0;
      }
      @media(max-width:350px) {
        columns:1;
      }
      li {
        margin-left:65px;

        @media(max-width:600px) { margin-left:0; }
        text-transform: uppercase;
        text-align: left;
        padding: 5px 0;
      }
    }
  }
  .btm {
    padding:30px 0;
    background:#012B50;
  }
  .col-half {
    width:50%;
    display:inline-block;
    vertical-align: top;
    &:nth-child(2) {
      text-align: right;
      @media(max-width:900px) { margin-top:35px; text-align:left; }
    }
    @media(max-width:900px) {
      width:100%;
      text-align: left;
    }
    .hr {
      display: block;
      width:100%;
      height:50px;
    }
    strong {
      display: inline-block;
      margin-right:5px;
      font-weight:700;
    }
    span {
      display: block;
    }
    .address {

    }
  }
}
