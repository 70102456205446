.banners {
  .item {
    height:705px;
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
    position: relative;
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width:100%;
      min-height:calc(100vh - 155px);
      z-index: 110;
      background-image: linear-gradient(-90deg, #2B7DC7 1%, rgba(42,125,199,0.25) 50%, #2B7DC7 99%);
      height:705px;
    }
    .overlay-txt {
      position: absolute;
      top:0;
      left:0;
      min-height:calc(100vh - 155px);
      width:100%;
      z-index: 200;
      padding-top:120px;
      @media(max-width:900px) { padding-top:100px; }
      @media(max-width:600px) { padding-top:80px; }
      @media(max-width:450px) { padding-top:60px; }
      h1 {
        font-size:48px;
        line-height: 1.25em;
        color:white;
        max-width:983px;
        font-weight:600;
        margin:0 0 35px;
        @media(max-width:900px) { font-size:41px; line-height: 1.25em; }
        @media(max-width:600px) { font-size:36px; line-height: 1.25em; }
        @media(max-width:450px) { font-size:30px; line-height: 1.25em; }
      }
      p {
        font-family: $sub-title-font-family;
        font-size:25px;
        line-height: 1.5em;
        color:white;
        max-width:830px;
        margin:0 0 50px;
        font-weight: 100;
        @media(max-width:900px) { font-size:23px; line-height: 1.5em; }
        @media(max-width:600px) { font-size:20px; line-height: 1.5em; }
        @media(max-width:450px) { font-size:18px; line-height: 1.5em; }
      }
    }

  }
}
.leftBannerBtn {
  position: absolute;
  left:20px;
  top:322px;
  z-index: 500;
  width:39px;
  height:39px;
  line-height: 39px;
  border-radius:50%;
  border:2px #fff solid;
  text-align: center;
  cursor: pointer;
  i {
    font-size:34px;
    color:#fff;
    position: relative;
    left: -1px;
  }
  @media(max-width:1100px) {
    display: none !important;
  }
}
.rightBannerBtn {
  @extend .leftBannerBtn;
  left:auto;
  right:50px;
  i {
    left:1px;
  }
}

.banner-paging {
  position: absolute;
  bottom:80px;
  left:0;
  z-index: 200;
  width:100%;
  ul.slick-dots {
    display: block;
    margin:0 auto;
    li {
      display: inline-block;
      margin-right:10px;
      button {
        background: #FFFFFF;
        width:10px;
        height:14px;
        display: inline-block;
        border:1px #fff solid;
        text-indent:-1000px;
        overflow: hidden;
        border-radius:50%;
      }
      &.slick-active {
        button {
          background:transparent;
        }
      }
    }
  }
}

.subbanner {
  padding-top:100px;
  margin-bottom:60px;
  //@media(max-width:900px) { padding-top:80px; }
  //@media(max-width:600px) { padding-top:65px; }
  //@media(max-width:450px) { padding-top:50px; }
  .item {
    background-position: center;
    background-repeat:no-repeat;
    background-size:cover;
    min-height:495px;
    position: relative;
    h1, h2 {
      font-size:46px;
      line-height: 1.15em;
      color:#0C2B36;
      max-width:750px;
      font-weight:600;
      margin:0 0 25px;
      color:#fff;
      @media(max-width:900px) { font-size:38px; line-height: 1.15em; }
      @media(max-width:600px) { font-size:32px; line-height: 1.15em; }
      @media(max-width:450px) { font-size:29px; line-height: 1.15em; }
    }
    p {
      font-size:25px;
      line-height: 1.3em;
      color:white;
      max-width:750px;
      margin:0;
      font-weight: 100;
      font-family: $sub-title-font-family;
      @media(max-width:900px) { font-size:24px; line-height: 1.2em; }
      @media(max-width:600px) { font-size:20px; line-height: 1.2em; }
      @media(max-width:450px) { font-size:18px; line-height: 1.2em; }

    }
  }
  .overlay {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width:100%;
    z-index: 130;
    background-image: linear-gradient(-90deg, #2B7DC7 1%, rgba(42,125,199,0.25) 50%, #2B7DC7 99%);
    @include respond-to(mobile-nav) {
      top:0;
    }
  }

  .overlay-txt {
    min-height:495px;
    left:0;
    padding-top:120px;
    @media(max-width:900px) { padding-top:90px; }
    @media(max-width:600px) { padding-top:75px; }
    @media(max-width:450px) { padding-top:60px; }
    position: absolute;
    top:0;
    width:100%;
    z-index: 200;
  }
}
